<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <h2 class="card-title">Neuigkeiten</h2>
                    </div>

                    <div class="card-body">
                        <template v-if="dashboardData.news.length > 0">
                            <ul class="products-list product-list-in-card pl-2 pr-2">
                                <li class="item" v-for="news in dashboardData.news" :key="news.id">
                                    <div class="product-img">
                                        <img :src="news.img_mobile" class="img-size-50" />
                                    </div>
                                    
                                    <div class="product-info">
                                        <router-link :to="{name: 'news.show', params: {id: news.id}}">
                                            {{ news.title }} 
                                            <span v-for="topic in news.topics" :key="topic.id" class="badge float-right mr-1" :class="topic.color">{{ topic.name }}</span>
                                        </router-link>
                                        <span class="product-description">
                                            {{ news.description_short }}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </template>
                        <template v-else>
                            <h3>Es liegen keine News vor</h3>
                        </template>
                    </div>

                </div>
            </div>
        </div>
        <div class="row" v-if="$auth.check('reports.open') || $auth.check('reports.orders') || $auth.check('reports.posten') || $auth.check('reports.amount') || $auth.check('reports.visitors') || $auth.check('reports.conversions')">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <h2 class="card-title">Allgemeine Umsatzdaten</h2>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.open')">
                                <h2>Offene Bestellungen</h2>
                            </div>

                            <div class="col-lg-2 col-6" v-if="$auth.check('reports.posten')">
                                <h2>Offene Posten</h2>
                            </div>

                            <div class="col-lg-2 col-6" v-if="$auth.check('reports.posten')">
                                <h2>OP (Anz.)</h2>
                            </div>

                            <div class="col-lg-2 col-6" v-if="$auth.check('reports.posten')">
                                <h2>Mahnfähig</h2>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.open')">
                                <h2>Kundenzähler</h2>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.open')">
                                <div class="small-box bg-secondary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.orders_open }}</h3>
                                        <p>Offene Bestellungen</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-6" v-if="$auth.check('reports.posten')">
                                <div class="small-box bg-secondary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.posten_open | toCurrency }}</h3>
                                        <p>Offene Posten</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-6" v-if="$auth.check('reports.posten')">
                                <div class="small-box bg-secondary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.posten_open_qty }}</h3>
                                        <p>OP (Anz.)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-6" v-if="$auth.check('reports.posten')">
                                <div class="small-box bg-secondary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.dunning }}</h3>
                                        <p>Mahnfähig</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.open')">
                                <div class="small-box bg-secondary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.customers_count }}</h3>
                                        <p>Kundenzähler</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div class="row mt-4">
                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.amount')">
                                <h2>Umsatz</h2>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.orders')">
                                <h2>Bestellungen</h2>
                            </div>

                            <!-- <div class="col-lg-2 col-6" v-if="$auth.check('reports.orders_open')">
                                <h2>Seitenaufrufe</h2>
                            </div> -->

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.visitors')">
                                <h2>Besucher</h2>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.conversion')">
                                <h2>Conversion-Rate</h2>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.amount')">
                                <div class="small-box bg-primary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.sales_today | toCurrency }}</h3>
                                        <p>Umsatz (Heute)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.orders')">
                                <div class="small-box bg-success">
                                    <div class="inner">
                                        <h3>{{ dashboardData.orders_today }}</h3>
                                        <p>Bestellungen (Heute)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-2 col-6" v-if="$auth.check('reports.orders_open')">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{ dashboardData.pageviews_today }}</h3>
                                        <p>Seitenaufrufe (Heute)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.visitors')">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{ dashboardData.visitors_today }}</h3>
                                        <p>Besucher (Heute)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.conversion')">
                                <div class="small-box bg-navy">
                                    <div class="inner">
                                        <h3>{{ dashboardData.conversion_today / 100 | toPercent }}</h3>
                                        <p>Conversion-Rate (Heute)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.amount')">
                                <div class="small-box bg-primary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.sales_current_month | toCurrency }}</h3>
                                        <p>Umsatz (Aktueller Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.orders')">
                                <div class="small-box bg-success">
                                    <div class="inner">
                                        <h3>{{ dashboardData.orders_current_month }}</h3>
                                        <p>Bestellungen (Aktueller Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-2 col-6" v-if="$auth.check('reports.orders_open')">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{ dashboardData.pageviews_current_month }}</h3>
                                        <p>Seitenaufrufe (Aktueller Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.visitors')">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{ dashboardData.visitors_current_month }}</h3>
                                        <p>Besucher (Aktueller Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.conversion')">
                                <div class="small-box bg-navy">
                                    <div class="inner">
                                        <h3>{{ dashboardData.conversion_current_month / 100 | toPercent }}</h3>
                                        <p>Conversion-Rate (Aktueller Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.amount')">
                                <div class="small-box bg-primary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.sales_last_month | toCurrency }}</h3>
                                        <p>Umsatz (Letzter Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.orders')">
                                <div class="small-box bg-success">
                                    <div class="inner">
                                        <h3>{{ dashboardData.orders_last_month }}</h3>
                                        <p>Bestellungen (Letzter Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-2 col-6" v-if="$auth.check('reports.orders_open')">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{ dashboardData.pageviews_last_month }}</h3>
                                        <p>Seitenaufrufe (Letzter Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.visitors')">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{ dashboardData.visitors_last_month }}</h3>
                                        <p>Besucher (Letzter Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.conversion')">
                                <div class="small-box bg-navy">
                                    <div class="inner">
                                        <h3>{{ dashboardData.conversion_last_month / 100 | toPercent }}</h3>
                                        <p>Conversion-Rate (Letzter Monat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.amount')">
                                <div class="small-box bg-primary">
                                    <div class="inner">
                                        <h3>{{ dashboardData.sales_last_year | toCurrency }}</h3>
                                        <p>Umsatz (Vorjahresmonat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.orders')">
                                <div class="small-box bg-success">
                                    <div class="inner">
                                        <h3>{{ dashboardData.orders_last_year }}</h3>
                                        <p>Bestellungen (Vorjahresmonat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-2 col-6" v-if="$auth.check('reports.orders_open')">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{ dashboardData.pageviews_last_year }}</h3>
                                        <p>Seitenaufrufe (Vorjahresmonat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.visitors')">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>{{ dashboardData.visitors_last_year }}</h3>
                                        <p>Besucher (Vorjahresmonat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-6" v-if="$auth.check('reports.conversion')">
                                <div class="small-box bg-navy">
                                    <div class="inner">
                                        <h3>{{ dashboardData.conversion_last_year / 100 | toPercent }}</h3>
                                        <p>Conversion-Rate (Vorjahresmonat)</p>
                                    </div>
                                    <div class="icon">
                                        <i class="ion ion-bag"></i>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>          
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: "Dashboard",
  title: "Dashboard",

  data(){
        return{
            dashboardData: {
                news: [],
            },
        }
  },
  
  mounted(){
    this.loadDashboard();
  },

  methods:{
    loadDashboard(){
      this.axios
          .get("/dashboard")
          .then((response) => {
              this.dashboardData = response.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    }
  }
};
</script>

<style scoped>
    .products-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .products-list>.item {
        background-color: #343a40;
        color: #fff;
        border-bottom-color: #6c757d;
    }

    .product-list-in-card>.item {
        border-radius: 0;
        border-bottom: 1px solid rgba(0,0,0,.125);
    }

    .products-list>.item {
        border-radius: 0.25rem;
        background-color: #fff;
        padding: 10px 0;
    }

    .products-list .product-img {
        float: left;
    }

    .products-list .product-info {
        margin-left: 60px;
    }

    .products-list .product-description {
        color: #6c757d;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .product-description {
        color: #ced4da;
    }
</style>
